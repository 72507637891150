
import { useAPI } from "@/use";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: 'AuthTelegram',
  created() {
    this.authMethod();
  },
  methods: {
    async authMethod() {
      const result = await useAPI().common.auth.loginMethod(this.$route.query);
      this.$store.dispatch("onLogin", result.data);
    }
  }
})
