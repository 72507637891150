
import { defineComponent } from "vue-demi";
import TelegramMain from "@/components/auth/Telegram.vue";

export default defineComponent({
  name: "AuthTelegramView",
  components: {
    TelegramMain,
  },
});
